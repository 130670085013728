.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: white;
  padding: 1rem;
  width: 900px;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.topicsList {
  width: 500px;
  border: solid 1px #ccc;
  border-radius: 12px;
  padding: 12px;
}

.topicsList:active,
.topicsList:focus {
  outline: 0;
}
