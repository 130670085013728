.bill {
  text-decoration: none;
  border: 0;
  outline: 0;
  background-color: #eaeffa;
  color: #0157ff;
  padding: 3px 15px;
  border-radius:20px;
  margin: 8px 0;
  margin-right: 5px;
  font-family: "Regular";
  border: solid 1px transparent;
}

.bill:hover{
  cursor: pointer;
  border: solid 1px #0157ff;
  background-color: #eaeffa;
  color: #0157ff;
}

.active_ {
  background-color: #0157ff;
  color: #fff;
}
