.container {
  background-color: var(--white);
  padding-inline: var(--desktop-padding--inline);
  padding-block: var(--desktop-padding--block);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 40px;
  row-gap: 32px;
  /* padding: var(--main-padding); */
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 24px;
  text-align: center;
  color: var(--main-colo);
}

.description {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
  color: var(--paragraph-color);
  margin-bottom: 56px;
  /* width: 80%; */
  margin-inline: auto;
}

.card {
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 4px 4px 4px rgba(237, 237, 237, 0.25);
  border-radius: 8px;
  align-items: center;
  height: 223px;
  /* width: 406px; */
  display: flex;
  flex-direction: column;
  padding: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

}

.animatedIndicator {
  position: absolute;
  bottom: 0;
  background-color: var(--secondary-color);
  height: 2px;
  width: 100%;
  align-self: center;
  animation: animate-indicator .5s forwards;
}

.card:hover {
  scale: 1.05;
  transition: .5s;
}

.img {
  height: 32px;
  width: 32px;
  margin-bottom: 8px;
}

.txt {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 16px;
  color: var(--secondary-color);
}

.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--paragraph-color);
}


@keyframes slide-top {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animate-indicator {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@media only screen and (min-width: 1224px) {}

@media only screen and (min-width: 481px) and (max-width: 1224px) {
  .container {
    padding-block: var(--ipad-padding--block);
    padding-inline: var(--ipad-padding--inline);
  }

  .grid {
    grid-template-columns: repeat(2, auto);
    column-gap: 8px;
    row-gap: 24px;
  }

  .title {
    font-size: 40px;
  }

  .description {
    font-size: 14px;
  }

  .txt {
    font-size: 16px;
  }

  .desc {
    font-size: 14px;
  }

  .card {
    height: 224px;
  }
}

@media (max-width: 480px) {
  .container {
    padding-block: var(--mobile-padding--block);
    padding-inline: var(--mobile-padding--inline);
  }

  .grid {
    grid-template-columns: repeat(1, auto);
  }

  .title {
    font-size: 18px;
  }

  .description {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .txt {
    font-size: 14px;
  }

  .desc {
    font-size: 10px;
  }

  .card {
    height: 200px;
  }
}