@font-face {
  font-family: "Light";
  src: local("Poppins-Light"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: local("Poppins-Regular"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("Poppins-Medium"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  font-family: "regular";
  box-sizing: border-box;
  color: #000;
}

a {
  margin: 0;
}

:root {
  --main-color: #1c1c1c;
  --secondary-color: #fff;
  --white: #ffffff;
  --text-color: #27272730;
  --paragraph-color: #8f8f8f;
  --background-color: #b0b0b0;
  --card-color: #f9f9f9;
  --blue-light-color: #eaeffa;
  --green: #0dd34f;
  --green-light-color: #d4fce1;
  --red: ##f20404;
  --main-padding: 5vw;

  --desktop-padding--inline: 71px;
  --desktop-padding--block: 71px;

  --ipad-padding--inline: 48px;
  --ipad-padding--block: 88px;

  --mobile-padding--inline: 24px;
  --mobile-padding--block: 56px;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

body {
  background-color: var(--white);
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0.3rem;
  height: 0.1rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
