.container {
  padding: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
}

.image {
  width: 77px;
  height: 77px;
  margin-bottom: 24px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-family: "Medium";
  margin-bottom: 16px;
}
.description {
  font-size: 14px;
  line-height: 21px;
  font-family: "Regular";
  margin-bottom: 16px;
  text-align: center;
  color: #8f8f8f;
  width: 300px;
}

.btnCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
}

.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #ffffff;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
}

.btnTxt {
  font-size: 16px;
  line-height: 24px;
  font-family: "Regular";
  text-align: center;
  color: var(--white);
}
.cancelTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1c1c;
  font-family: "Regular";
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}
