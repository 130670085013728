.card {
  background: #ffffff;
  border-radius: 8px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-family: "Medium";
  margin-top: 16px;
}

.subTitle {
  font-size: 14px;
  line-height: 22px;
  color: #8f8f8f;
  margin-top: 9px;
  font-family: "Regular";
}

.grid {
  display: grid;
  column-gap: 35px;
  grid-template-columns: repeat(2, 45%);
}

.col {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.englishForm {
  height: 37vh;
  width: 100%;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 0.75rem;
}

.englishForm::-webkit-scrollbar{
  width: 0;
}

.label {
  font-size: 14px;
  line-height: 22px;
  color: #8f8f8f;
  font-family: "Regular";
  margin-top: 40px;
  margin-bottom: 16px;
}

.area {
  padding: 12px;
  border-color: #dcdcdc;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
  margin-top: 8px;
}

.arabicArea {
  padding: 12px;
  border-color: #dcdcdc;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
  text-align: right;
}

.cardInput {
  background: #ffffff;
  border: 1px solid #fbfbfb;
  box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
  border-radius: 8px;
  padding-inline: 12px;
  padding-bottom: 90px;
  height: 40vh;
  margin-bottom: 2rem;
}



.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #1c1c1c;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
}

.submitTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: "Regular";
}

.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #ffffff;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
  margin-inline-end: 1rem;
}

.cancelTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1c1c;
  font-family: "Regular";
}

.rowBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 3.8rem;
  margin-block: 1rem;
}
