.itemWrapper {
  cursor: pointer;
  padding: 8px 4px;
  color: #0157ff;
  background-color: #eaeffa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-radius: 12px;
  font-size: 11px;
  font-family: "Regular";
}
