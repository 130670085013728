.addTaskContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.backBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.backBtn span {
  margin-left: 3px;
  color: gray;
  font-family: "Regular";
}

.backBtn span:hover {
  margin-left: 7px;
}

.parent {
  position: relative;
  width: 100%;
}

.container {
  padding: 16px;
  border-radius: 12px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.tasks {
  flex: 1;
  background-color: #fff;
  margin-right: 20px;
  padding: 20px;
  height: 31.8125rem;
}

.tasksContainer {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 0 10px;
  border-radius: 8px;
  height: 45vh;
  overflow-y: auto;
}

.tasksContainer .taskItem {
  cursor: pointer;
  background-color: #fdfdfd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
}

.tasksContainer .selected {
  cursor: pointer;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
}

.label {
  color: #ccc;
  font-size: 14px;
}

.editTasks {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  height: 35vh;
  border-radius: 8px;
}

.editTasks .customTextArea {
  width: 70%;
  max-width: 70%;
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  padding: 0.4rem;
  overflow: hidden;
  margin: 10px 0;
}

.btnDiv {
  display: flex;
  justify-content: flex-end;
}

.save {
  cursor: pointer;
  text-decoration: none;
  background-color: #000;
  font-family: "Regular";
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 10px 30px;
}

.save:hover {
  color: #fff;
}

.addTaskBtn {
  cursor: pointer;
  display: block;
  width: 100px;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: blue;
  font-family: "Regular";
  width: 100px;
}
