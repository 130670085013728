.container {
  background-color: #fff;
  height: 520px;
  padding: 12px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}

.dropdownHolder {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-right: 12px;
}

.label {
  display: inline-block;
  width: 100px;
  font-size: 12px;
  color: #272727;
  font-weight: 400;
}

.select {
  width: 300px;
  border-radius: 8px;
  padding: 8px 12px;
  border: solid 1px #ccc;
  color: #ccc;
}

.select:focus,
.select:active {
  outline: 0;
  border: solid 1px #ccc;
}

option {
  color: #272727;
  font-size: 14px;
}
