.labelAndInput {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.bigTextarea {
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 8px;
  width: 500px;
  height: 6rem;
  max-height: 8rem;
  margin: 10px;
  margin-left: 0;
}

.normalTextarea {
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 8px;
  width: 500px;
  height: 2.8rem;
  margin: 10px;
  margin-left: 0;
}

textarea:focus {
  outline: 0;
}
