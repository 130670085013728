.container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.addVideoContainer1 {
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  padding: 8px;
  border-radius: 12px;
  margin: 4px;
  margin-bottom: 2rem;
}

.addVideoContainer {
  height: 45vh;
  background-color: #fff;
  border-radius: 12px;
  margin: 0px;
}

.grid {
  display: grid;
  column-gap: 35px;
  grid-template-columns: repeat(2, 45%);
  margin-top: .5rem;
}

.form {
  height: 45vh;
  width: 100%;
  padding: .5rem;
}

.englishForm {
  height: 40vh;
  width: 100%;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 0.75rem;
}

.englishForm::-webkit-scrollbar {
  width: 0px;
}

.detailsArea {
  width: 500px;
  border-radius: 12px;
  border: solid 1px #ccc;
  padding: 4px 8px;
  margin-top: 8px;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  cursor: pointer;
  border: solid 1px #ccc;
  border-radius: 6px;
  padding: 8px 16px;
  font-family: "Regular";
  margin-right: 12px;
  background-color: #fff;
  font-size: 16px;
}

.uploadVideo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}

.required {
  color: red;
  display: inline-block;
  margin-left: 3px;
}

.topicsList {
  width: 500px;
  border: solid 1px #ccc;
  border-radius: 12px;
  padding: 12px;
}

.topicsList:active,
.topicsList:focus {
  outline: 0;
}

.uploadVideoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 500px;
  margin-top: 1rem;
  border: dashed 5px #f5f5f5;
}
