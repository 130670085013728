.adminHeader {
  background: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  height: 100px;
}

.view {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.img {
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 50px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 8px;
  width: 396px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
}

.searchWrapper input[type="text"] {
  border: 0;
  width: 296px;
  outline: none;
}

.searchWrapper input[type="text"] :focus {
  border: 0;
  outline: none;
}

.notificationsLength {
  position: absolute;
  right: 20px;
  top: -2px;
  background-color: red;
  font-size: 12px;
  color: #fff;
  width: 15px;
  border-radius: 50%;
  text-align: center;
  font-family: 'Bold';
}
