.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 200px;
}

.list li {
  cursor: pointer;
  display: block;
  padding: 8px 0;
}

.tab {
  font-size: 12px;
  color: #ccc;
  font-weight: "Regular";
  padding: 8px 0;
}

.activeTab {
  padding: 8px 0;
  color: #000;
  font-size: 12px;
  font-weight: "Bold";
}
