.popupContainer {
  position: absolute;
  background-color: #f3f3f3;
  top: 100%;
  right: 80%;
  width: 280px;
  border: 1px solid #f3f3f3;
  z-index: 3;
  border-radius: 12px;
}

.notificationpopupList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  width: 100%;
  background-color: #fff;
  height: 500px;
  overflow-y: auto;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.notificationpopupList::-webkit-scrollbar {
  width: 3px;
}

.notificationpopupItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-bottom: solid 1px #ccc;
  padding: 12px;
}

.title {
  margin: 0;
  margin-right: 12px;
  color: #000;
  font-size: 16px;
  border-radius: 12px;
  font-family: "Regular";
  font-weight: 500;
}

.notificationpopupItem small {
  display: inline-block;
  margin-right: 12px;
  text-overflow: ellipsis;
  color: #b0b0b0;
  font-family: "Regular";
  font-size: 12px;
}

.notificationpopupItem:hover {
  background-color: #f3f3f3;
}
