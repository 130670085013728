.container {
  background: #1c1c1c;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-inline: var(--main-padding);
  padding-block: 20px;
  align-items: center;
}

.logo {
  width: 108px;
  height: 58px;
}

.copy {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--white);
  font-family: light;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 1224px) {}

@media only screen and (min-width: 670px) and (max-width: 1224px) {
  .copy {
    font-size: 12px;
    line-height: 21px;
    margin-top: 8px;
  }
}

@media (max-width: 669px) {
  .copy {
    font-size: 10px;
    line-height: 21px;
    margin-top: 10px;
  }
}