.filterView {
  background: #ffffff;
  border: 1px solid #f8f8f8;
  border-radius: 8px;
  width: 136px;
  height: 40px;
  margin-right: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  display: flex;
}
.filterName {
  font-size: 14px;
  line-height: 14px;
  color: rgba(39, 39, 39, 0.3);
  font-family: "Regular";
}

.downLoad {
  cursor: pointer;
  width: 48px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.import {
  width: 22px;
  height: 22px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 20px;
}

.userList {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #272727;
  font-family: "Medium";
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plus {
  cursor: pointer;
  width: 48px;
  height: 40px;
  background: #1c1c1c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
