.labelAndImageInputWrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.optionalText {
  color: #ccc;
  font-family: "Regular";
}

.inputAndImage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.imageInput {
  border: solid 1px #ccc;
  width: 500px;
  height: 40px;
  border-radius: 12px;
  visibility: none;
  display: none;
}

.selectImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 40px;
  border: solid 1px #ccc;
  border-radius: 12px;
  padding: 4px 16px;
  margin-top: 4px;
  height: 40px;
}

.selectImg small {
  color: #ccc;
  font-family: "Regular";
}
