.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: max-content;
  width: 100%;
}
.flat {
  height: 20%;
  background-color: #272727;
}
.center {
  padding: 20px;
}

.card1 {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

#rowId {
  width: 14.5rem;
}
#date {
  width: 6rem;
}
#slug {
  width: 8.5rem;
} 

 .card {
  height: 48px;
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkBox {
  border: 2px solid #c2c2c2;
  margin-right: 30px;
}

.img {
  width: 32px;
  height: 32px;
  border-radius: 81px;
}

.userName {
  font-family: "Regular";
  font-size: 11px;
  line-height: 18px;
  color: #272727;
  flex: 1;

}

.title {
  font-family: "Regular";
  font-size: 12px;
  line-height: 14px;
  color: rgba(39, 39, 39, 0.3);
  flex: 1;
}
