.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.img {
  width: 68px;
  height: 68px;
  margin-right: 18px;
  border-radius: 60px;
}

.circle {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 280px;
  border-radius: 42px;
  top: 240px;
  cursor: pointer;
}

.name {
  font-size: 18px;
  line-height: 27px;
  color: #272727;
  font-family: "Regular";
}

.email {
  font-size: 12px;
  line-height: 18px;
  color: #8f8f8f;
  font-family: "Regular";
}
