.parent {
  position: relative;
  width: 100%;
}
.container {
  height: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  margin: 12px;
}

.forms {
  display: flex;
  margin: 10px 0;
}

.detailsForm {
  height: 30vh;
  overflow-y: auto;
}
.formUk {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  column-gap: 35px;
  grid-template-columns: repeat(2, 45%);
}

.col {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.label {
  font-size: 14px;
  line-height: 22px;
  color: #8f8f8f;
  font-family: "Regular";
  margin-top: 16px;
  margin-bottom: 8px;
}

.area {
  padding: 12px;
  border-color: #dcdcdc;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
}

.salaryArea {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 500px;
  border: solid 1px #ccc;
  border-radius: 12px;
  margin: 8px 0;
  outline: 0;
  padding: 10px;
  height: 40px;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input; /* Firefox */
}
