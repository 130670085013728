.parent {
  position: relative;
  width: 100%;
}
.container {
  height: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  margin: 12px;
}

.forms {
  display: flex;
  margin: 10px 0;
}

.formUk {
  display: flex;
  flex-direction: column;
  height: 45vh;
  overflow-y: auto;
}

.formUk::-webkit-scrollbar {
  width: 0;
}
.addTaskBtn {
  cursor: pointer;
  display: block;
  width: 80px;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: blue;
  font-family: "Regular";
}

.tasksList {
  border-radius: 12px;
  border: solid 1px #f1ecec;
  width: 140px;
  text-align: center;
  padding: 10px;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
}

.tasksList span {
  margin-right: 5px;
  color: #cac5c5;
}

.tasksList span:hover {
  color: blue;
}
