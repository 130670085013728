.container {
  padding: 22px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 35rem;
  background: #ffffff;
}
.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.closeDiv {
  display: flex;
}

.btnClose {
  margin-left: auto;
  padding: 0px;
  border: none;
  background: none;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.simpleRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.userImg {
  width: 54px;
  height: 54px;
  margin-right: 12px;
}

.name {
  font-size: 14px;
  line-height: 24px;
  color: #272727;
  font-family: "Regular";
}

.career {
  font-size: 12px;
  line-height: 18px;
  color: rgba(39, 39, 39, 0.3);
  font-family: "Regular";
}

.actions {
  margin-right: 0.5rem;
}
.description {
  font-size: 14px;
  line-height: 22px;
  color: #8f8f8f;
  margin-block: 30px;
  font-family: "Regular";
}

.link {
  margin: 0;
}

.social {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-inline: 2px;
}

.loading {
  width: 520px;
  align-self: center;
}
