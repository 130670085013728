.header {
  display: flex;
  flex-direction: column;
}

.header .title {
  font-family: "Regular";
}

.header small {
  color: #8f8f8f;
  font-family: "Regular";
}
