.card {
  cursor: pointer;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 17rem;
  height: 8.5rem;
  padding: 8px;
  margin: 10px;
  display: flex;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 5px 5px 5px 1px rgba(231, 226, 226, 0.35);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.card:hover {
  background-color: #f9f9f9;
}

.upperSide {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.title {
  font-family: "Regular";
  color: #272727;
  font-size: 16px;
  line-height: 24px;
}

.createdDate {
  font-size: 10px;
  line-height: 18px;
  color: rgba(39, 39, 39, 0.3);
  font-family: "Regular";
  margin-top: 4px;
}

.description {
  font-size: 12px;
  line-height: 21px;
  color: #b9b9b9;
  margin-bottom: 2rem;
  font-family: "Regular";
  min-height: 2rem;
  max-height: 2.5rem;
  height: 2.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.photos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.photos img {
  border: inset solid 1px #fff;
}

.numberOfRemainingPhotos {
  width: 28px;
  height: 28px;
  color: #fff;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-size: 0.56rem;
  margin-right: -8px;
  margin-bottom: 5px;
  font-weight: 200;
  border: solid 1px #fff;
}
