.title {
  color: #272727;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selected {
  color: #fff !important;
}

.header {
  outline: 0;
}
