.breadcrumbContainer {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.backBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.backBtn span {
  margin-left: 3px;
  color: gray;
  font-family: "Regular";
}

.backBtn span:hover {
  margin-left: 7px;
}


.breadcrumbPages .breadcrumbTab {
  margin-right: 5px;
  margin-inline-end: 0;
  display: inline !important;
}
.breadcrumbPages .breadcrumbTab:hover {
  color: rgba(39, 39, 39, 0.3);
}

.breadcrumbPages span {
  margin-right: 5px;
}

.active_ {
  color: blue;
  font-size: 16px;
  font-weight: 600;
}

.disabled {
  color: rgba(39, 39, 39, 0.3);
  font-weight: 300;
}

.actionButtons {
  all: unset;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #000;
  margin: 20px;
  background-color: transparent;
}

.actionButtons button,
.actionButtons a {
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  font-family: "Regular";
  width: 6.5rem;
  padding: 8px 0;
}

.cancel {
  color: #000;
  border: solid 1px #8f8f8f;
  margin-right: 15px;
}

.next {
  background-color: black;
  color: #fff;
}

.next:hover {
  color: #fff;
  background-color: #000;
}
