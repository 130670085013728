.menuItems {
  position: absolute;
  right: 30px;
  top: -2px;
  z-index: 2;
  border-radius: 8px;
}

.menuItem {
  margin: 0;
  background-color: #f1e8e8;
  padding: 10px 15px;
  font-family: "Regular";
}

.menuItem:hover {
  background-color: #e2dbdb;
}
