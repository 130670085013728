.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  width: 22px;
  height: 22px;
  margin-left: 22px;
  cursor: pointer;
}

.switch {
  width: 22px;
  height: 22px;
  margin-left: 22px;
}

/* Hide the checkbox */
.toggle input {
  display: none;
}

/* Style the slider */
.toggle .slider {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 11px;
  background-color: gray;
  border-radius: 25px;
  transition: background-color 0.2s;
  margin-left: 22px;
}

/* Style the slider when checked */
.toggle input:checked + .slider {
  background-color: rgba(28, 28, 28, 0.1);
}

/* Style the slider's circle */
.toggle .slider::before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  background-color: black;
  border-radius: 50%;
  bottom: -3px;
  /* left: 0.5px; */
  transition: transform 0.2s;
}

/* Style the slider's circle when checked */
.toggle input:checked + .slider::before {
  transform: translateX(15px);
}
