.modal {
  background-color: var(--card-color);
  padding: 20px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 80vh;
  width: max-content;
}

.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.closeDiv {
  display: flex;
}

.video {
  border-radius: 10px;
}

.btnClose {
  margin-left: auto;
  padding: 0px;
  border: none;
  background: none;
}

.imgWrapper {
  width: 520px;
  align-self: center;
  height: 200px;
  margin-block: 16px;
}

.loading {
  width: 520px;
  align-self: center;
}

.modalHeader {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.title {
  margin: 0;
  display: inline;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-family: "Medium";
  margin-bottom: 12px;
}

.subtitle {
  font-size: 14px;
  line-height: 21px;
  font-family: "Regular";
  color: #272727;
  margin-top: 10px;
}

.grayText {
  color: #8f8f8f;
  font-size: 12px;
  margin: 8px 0;
  font-family: "Regular";
  width: 530px;
  margin-top: 0px;
}

h5 {
  margin: 8px 0;
  font-family: "Regular";
}

.photos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.photos img {
  border: inset solid 1px #fff;
}

.numberOfRemainingPhotos {
  width: 28px;
  height: 28px;
  color: #fff;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-size: 0.56rem;
  margin-right: -8px;
  margin-bottom: 5px;
  font-weight: 200;
  border: solid 1px #fff;
}

.tags {
  width: 530px;
  margin-bottom: 12px;
}
