.autocomplete-container {
  position: relative;
  width: 500px;
  margin: 16px 0;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 12px;
  cursor: text;
  height: auto;
  min-height: 40px;
  margin-top: 8px;
}

.tag {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: 2px;
  background-color: #0157ff;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-family: "Regular";
}

.remove-button {
  margin-left: 4px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #0157ff;
  cursor: pointer;
  background-color: #fff;
}

.input {
  flex: 1;
  border: none;
  margin-left: 4px;
  outline: none;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 500px;
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: 4px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 160px;
  overflow-y: auto;
}

.option {
  padding: 4px;
  cursor: pointer;
}

.option:hover {
  background-color: #e6e6e6;
}

.option.active {
  background-color: #c7c7c7;
}
