.container {
  display: flex;
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #f2f2f2;
  flex-direction: column;
  background: #ffffff;
  padding-right: 0px;
  padding-left: 1.2px;
}

.logoWrapper {
  padding: 1vw;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.logo {
  width: 108px;
  height: 58px;
}

.tab {
  padding: 1vw;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: row;
  width: 100%;
}

.iconWithTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.arrowIcon {
  margin-left: -10px;
  text-align: center;
  vertical-align: center;
}

.activeTab {
  border-left: solid 2px #000;
}

.title {
  font-size: 16px;
  color: #ccc;
  font-weight: 200;
  margin-left: 20px;
}

.active {
  color: #000;
  font-weight: 500;
}

.subTab {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  /* padding-left: 20px; */
  flex-direction: row;
  margin-block: 5px;
}

.dropdownLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 5rem;
  margin-top: 10px;
}

.dropdownLinkActive {
  color: #000;
  font-family: "Regular";
}

.subNavBtn {
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
