.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.center {
  padding: 20px;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.left {
  display: flex;
  align-items: center;
  margin-block: 5px;
  flex-direction: row;
  margin-left: 80%;
}
.btn {
  padding: 0px;
  background: none;
  border: 0px;
  margin-top: 5px;
  cursor: pointer;
}
