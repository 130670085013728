.card {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 25px;
  height: 65vh;
}

.center {
  padding: 20px;
}

/* // My Info */

.row {
  display: flex;
  flex-direction: row;
  z-index: 10;
}

.rowInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.name {
  font-size: 14px;
  line-height: 40px;
  color: #1c1c1c;
  font-family: "Regular";
  margin-right: 32px;
  border-bottom: 2px solid #1c1c1c;
  cursor: pointer;
  padding-inline: 5px;
  border-radius: 1px;
}

.nameDisabled {
  font-size: 14px;
  line-height: 40px;
  color: rgba(39, 39, 39, 0.3);
  font-family: "Regular";
  margin-right: 32px;
  border-bottom: 2px solid #f3f3f3;
  cursor: pointer;
  padding-inline: 5px;
  border-radius: 1px;
}

.line {
  width: 100%;
  border: 1px solid #f3f3f3;
  margin-top: -2px;
  z-index: 0;
  margin-bottom: 24px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 8px;
  width: 261px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  outline: none;
  margin-right: 15px;
}

.emailWrapper {
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 8px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  outline: none;
  width: 537px;
}

.label {
  font-size: 12px;
  line-height: 18px;
  color: #272727;
  font-family: "Regular";
  margin-bottom: 15px;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #1c1c1c;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
}

.isDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #6f6e6e;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
}

.isDisabledTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: "Regular";
}

.submitTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: "Regular";
}

.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #ffffff;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
  margin-inline-end: 1rem;
}

.cancelTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1c1c;
  font-family: "Regular";
}

.rowBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-block: 1rem;
}

/* // My Passwords  */

.passwordLabel {
  font-size: 12px;
  line-height: 18px;
  color: #272727;
  font-family: "Regular";
  margin-right: 200px;
  width: 140px;
}

.rowPassword {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.passwordInput {
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 8px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  outline: none;
  width: 537px;
}

.inputPassword {
  outline: none;
  width: 500px;
  border: 0px;
}

.linePassword {
  width: 100%;
  border-bottom: 1px solid #f3f3f3;
  margin-block: 16px;
}

/* // My Notifications */

.notifyView {
  width: 268px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid #dcdcdc;
}

.notifyLabel {
  font-size: 14px;
  line-height: 21px;
  color: #0157ff;
  font-family: "Regular";
  background: #eaeffa;
  width: 33.33333%;
  height: 39px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.notifyLabelDisable {
  font-size: 14px;
  line-height: 21px;
  color: rgba(39, 39, 39, 0.3);
  font-family: "Regular";
  width: 33.33333%;
  height: 39px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
