.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.center {
  padding: 20px;
}

.overviewHeader {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overviewHeaderFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.chart {
  background-color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  padding: 0 8px 0 0;
  border-radius: 15px;
  overflow-y: auto;
  height: 400px;
  
}

.chart::-webkit-scrollbar{
  width: 0;
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 16px;
}

.chart h3 {
  font-family: "Regular";
}

.calendarContainer {
  cursor: pointer;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fcfcfc;
  padding: 8px;
  border-radius: 8px;
}

.calendarContainer small {
  color: #ccc;
  font-size: 14px;
  font-family: "Regular";
}
