.card {
  flex-basis: calc(25% - 20px); /* 4 cards in one row, with 20px gap between them */
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #fff; 
  box-shadow: 10px 10px 5px 0px rgba(231, 226, 226, 0.35);

}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.imgWrapper {
  background-color: #F9F9F9;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  color: #fff
}

.count{
  font-size: 16px;
  margin: 10px 0;
  font-weight: bold;
}

.title{
  font-weight: 200;
}