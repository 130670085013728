.calendar_ {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
}

.closeButton {
  background-color: transparent;
  text-align: right;
  border: 0;
  cursor: pointer;
  margin-bottom: 10px;
}

.close {
  width: 20px;
  height: 20px;
}

.btnCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  margin-left: auto;
  padding-inline: 12px;
  padding-block: 8px;
}

.btnTxt {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--white);
}
