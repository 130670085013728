.links {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 13px;
  margin-top: 6px;
}

.face {
  width: 8px;
  margin-top: 4px;
}

.smallIcon {
  width: 1.4vw;
  height: 1.4vw;
  margin-inline-start: 0.5vw;
}

.link {
  margin: 0;
}

.circle {
  width: 30px;
  height: 30px;
  border: 1px solid var(--white);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 10px;
}

@media only screen and (min-width: 1224px) {}

@media only screen and (min-width: 670px) and (max-width: 1224px) {}

@media (max-width: 669px) {
  .icon {
    width: 12px;
    height: 12px;
  }
}