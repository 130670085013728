.container{ 
    width: 100%;
    background-color: #fcfcfc;
    padding: 16px;
}

.title{ 
    font-family: 'Regular';
    margin-bottom: 20px;
}

.accountSettingsContainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
