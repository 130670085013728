.container {
  background-color: var(--card-color);
  padding: 22px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 80vh;
}
.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.closeDiv {
  display: flex;
}

.video {
  border-radius: 10px;
}

.btnClose {
  margin-left: auto;
  padding: 0px;
  border: none;
  background: none;
}

.btnPlay {
  padding: 0px;
  border: none;
  background: none;
}

.background {
  width: 550px;
  height: 192px;
  /* background-image: url("../../assets/images/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  margin-block: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
}

.play {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  margin-top: 16px;
  font-family: "Medium";
}

.rowName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  flex-direction: row;
}

.name {
  font-size: 12px;
  line-height: 18px;
  color: #272727;
  margin-left: 8px;
  font-family: "Regular";
}

.profile {
  width: 24px;
  height: 24px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.description {
  font-size: 14px;
  line-height: 22px;
  color: #8f8f8f;
  font-family: "Regular";
  margin-top: 16px;
  width: 550px;
}

.date {
  font-size: 12px;
  line-height: 16px;
  color: #8f8f8f;
  font-family: "Regular";
  margin-top: 4px;
}

.col {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.approval {
  font-size: 12px;
  line-height: 16px;
  color: #272727;
  font-family: "Regular";
}

.check {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.approved {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #0dd34f;
  text-align: center;
  width: 20vh;
  background: rgba(94, 255, 147, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}

.rejected {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #f20404;
  text-align: center;
  width: 20vh;
  background: rgba(255, 113, 113, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}
.review {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #f49800;
  text-align: center;
  width: 20vh;
  background: #f498001a;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}

.modify {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #0d50d3;
  text-align: center;
  width: 20vh;
  background: rgba(94, 177, 255, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  padding-block: 8px;
  width: 108px;
  background: #1c1c1c;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 8px;
  margin-left: auto;
}

.submitTxt {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  font-family: "Regular";
}
