.container {
  background-color: var(--card-color);
  padding-block: 40px;
  padding-inline: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--background-color);
  margin-bottom: 21px;
  margin-top: 16px;
}

.logo {
  height: 37px;
  width: 68px;
}

.btnCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  background: var(--main-color);
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  width: 100%;
}

.btnTxt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white);
}

.form {
  flex-direction: column;
  width: 100%;
}

.input {
  border-bottom-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: #dcdcdc;
  line-height: 2.5;
  outline: none;
  background-color: transparent;
}

::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(39, 39, 39, 0.3);
}

.labelTxt {
  font-size: 12px;
  line-height: 18px;
  color: #272727;
}

.star {
  color: #f20404;
}

.col {
  flex-direction: column;
  display: flex;
  margin-top: 24px;
}

.remember {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--main-color);
  margin-left: -5px;
}

.forget {

  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: var(--main-color);
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 25px;
}