.loading-animation {
  display: block;
  margin: 0;
  width: 100%;
  height: 1.5px;
  background-color: #ccc;
  position: relative;
  overflow: hidden;
}

.loading-animation .line {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 2px;
  background-color: #0157ff;

  animation: line-animation 5s linear infinite;
}

@keyframes line-animation {
  from {
    left: 80%;
  }
  to {
    left: -80%;
  }
}
