.navbar {
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: var(--white);
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-inline: var(--main-padding);
  padding-block: 12px;
}

.logo {
  width: 108px;
  height: 58px;
}

.linksContainer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

a {
  margin-inline-end: 3.3vw;
  text-decoration: none;
  font-family: "regular";
  color: var(--text-color);
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  padding-inline: 8px;
}

.userIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-inline: 4px;
}

.active,
a:hover {
  color: var(--main-color);
}

@media only screen and (min-width: 481px) and (max-width: 1224px) {
}

@media (max-width: 480px) {
  .logo {
    width: 77px;
    height: 41px;
  }

  a {
    margin-inline-end: 5vw;
  }
}
