.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: max-content;
  width: 100%;
}
.flat {
  height: 20%;
  background-color: #272727;
}
.center {
  padding: 20px;
}

.card1 {
  height: 48px;
  display: flex;
  background: #ffffff;
  width: 100%;
  align-items: center;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.card {
  height: 50px;
  display: grid;
  background: #ffffff;
  grid-template-columns: repeat(6, auto);
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkBox {
  border: 2px solid #c2c2c2;
  margin-right: 30px;
}

.img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.userName {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #272727;
  text-align: left;
  flex: 1;
}

.name {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #272727;
  text-align: left;
  width: 20vh;
  cursor: pointer;
}

.title {
  font-family: "Regular";
  font-size: 12px;
  line-height: 14px;
  color: rgba(39, 39, 39, 0.3);
  flex: 1;
}

.approved {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #0dd34f;
  text-align: center;
  width: 20vh;
  background: rgba(94, 255, 147, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}

.rejected {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #f20404;
  text-align: center;
  width: 20vh;
  background: rgba(255, 113, 113, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}
.review {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #f49800;
  text-align: center;
  width: 20vh;
  background: #f498001a;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}

.modify {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: #0d50d3;
  text-align: center;
  width: 20vh;
  background: rgba(94, 177, 255, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
}

.left {
  display: flex;
  align-items: center;
  margin-block: 5px;
  flex-direction: row;
  margin-left: 90%;
}
.btn {
  padding: 0px;
  background: none;
  border: 0px;
  margin-top: 5px;
  cursor: pointer;
}
