.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: max-content;
  width: 100%;
  height: 100%;
}

.center {
  padding: 20px;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #272727;
  margin-top: 20px;
  font-family: "Medium";
}

.description {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #8f8f8f;
  font-family: "Regular";
  margin-top: 12px;
}
