.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.editContainer {
  background-color: #fff;
  padding: 16px;
  margin-top: 16px;
}

.editContainer .title {
  color: blue;
  font-family: "Regular";
}

.editContainer .subtitle {
  color: #ccc;
  display: inline-block;
  font-family: "Regular";
  margin: 16px 0;
}

.editUserForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.editUserForm input {
  width: 400px;
  height: 50px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  border: solid 1px #b0b0b0;
}

.editUserForm input:focus {
  border: solid 1px #b0b0b0;
  outline: 0;
}

.editUserForm .btnWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}

.button {
  cursor: pointer;
  padding: 10px 30px;
  background-color: #000;
  color: #fff;
  border-radius: 12px;
  border: 0;
  font-size: 16px;
  font-family: "Regular";
}

.buttonDisabled {
  cursor: pointer;
  padding: 10px 30px;
  background-color: #b0b0b0;
  color: #fff;
  border-radius: 12px;
  border: 0;
  font-size: 16px;
  font-family: "Regular";
}
