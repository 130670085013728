.rowBack {
  flex-direction: row-reverse;
}

.back {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: "Regular";
  color: rgba(39, 39, 39, 0.3);
  margin-inline-start: 8px;
}

.left {
  width: 17px;
  height: 17px;
}
