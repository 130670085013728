.uploadInput {
  border: 3px dashed #d6d4d4;
  padding: 16px;
  text-align: center;
  height: 150px;
  width: 500px;
}

.uploadingContainer {
  width: 500px;
  min-height: 120px;
  margin: 40px;
  background-color: #fdf8f8;
  padding: 18px 12px;
  border-radius: 12px;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.details {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.browseText {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
  color: #0157ff;
  background-color: transparent;
  border: 0;
}
