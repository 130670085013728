.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flat {
  height: 20%;
  background-color: #272727;
}

.center {
  padding: 20px;
}

.card1 {
  height: 48px;
  display: flex;
  width: 100%;
  background-color: #000;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.card {
  height: 48px;
  display: grid;
  background: #ffffff;
  grid-template-columns: repeat(5, auto);
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#rowId {
  width: 17.2rem;
}
#email {
  width: 13rem;
}

#role {
  width: 12.5rem;
}

#gender {
  width: 6.2rem;
}

.checkBox {
  border: 2px solid #c2c2c2;
  margin-right: 30px;
}

.img {
  width: 32px;
  height: 32px;
  border-radius: 81px;
  margin-inline-end: 8px;
}

.userName {
  font-style: "Regular";
  font-size: 11px;
  line-height: 18px;
  color: #272727;
  flex: 1;
}

.title {
  font-family: "Regular";
  font-size: 12px;
  line-height: 14px;
  color: rgba(39, 39, 39, 0.3);
  flex: 1;
}

.left {
  display: flex;
  align-items: center;
  margin-block: 5px;
  flex-direction: row;
  margin-left: 90%;
}

.btn {
  padding: 0px;
  background: none;
  border: 0px;
  margin-top: 5px;
  cursor: pointer;
}
