.popupContainer {
  position: absolute;
  background-color: #fff;
  top: 100%;
  right: 20%;
  border-radius: 12px;
  width: 220px;
  border: 1px solid #f3f3f3;
  z-index: 3;
}

.profilepopupList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  width: 100%;
  background-color: #fff;
}

.profilepopupItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Bold";
  font-size: 16px;
  width: 100%;
  padding: 12px;
}

.profilepopupItem span {
  margin-left: 12px;
  color: #272727;
}

.profilepopupItem:hover {
  background-color: #f3f3f3;
}
