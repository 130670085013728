.container {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fcfcfc;
}

.flat {
  height: 20%;
  background-color: #272727;
}
.center {
  padding: 20px;
}

.rowStyle {
  /* background: #ffffff; */
  border-radius: 10px;
  margin-bottom: 12px;
}

.card1 {
  display: grid;
  background: #ffffff;
  grid-template-columns: 13.7rem 11.3rem 6.9rem 9.2rem 12rem;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 400;
}

.card {
  height: 48px;
  display: grid;
  background: #ffffff;
  grid-template-columns: 13.4rem 11.3rem 6.9rem 9.2rem 12rem 12rem;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowPress {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.checkBox {
  border: 2px solid #c2c2c2;
  margin-right: 30px;
}

.img {
  width: 32px;
  height: 32px;
  border-radius: 81px;
}

.userName {
  font-family: "Regular";
  font-size: 10px;
  line-height: 18px;
  display: flex;
  margin-left: 8px;
  letter-spacing: 0.03em;
  color: red;
}

.title {
  font-family: "Regular";
  font-size: 12px;
  line-height: 14px;
  color: rgba(39, 39, 39, 0.3);
}

.paginationButtons {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  display: flex;
  align-items: center;
  margin-block: 5px;
  flex-direction: row;
  margin-left: 90%;
}
.btn {
  padding: 0px;
  background: none;
  border: 0px;
  margin-top: 5px;
  cursor: pointer;
}
