.parent {
  position: relative;
  width: 100%;
}
.container {
  height: 100%;
  max-height: 401.11px;
  overflow-y: hidden;
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  margin: 12px;
}

.forms {
  display: flex;
}

.form {
  height: 30vh;
  overflow-y: auto;
}
.formUk {
  display: flex;
  width: 100%;
  max-width: 40%;
  flex-direction: column;
  margin: 10px 0;
}

.formUk .labelAndInput {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.formUk .smallArea {
  border: solid 1px #ccc;
  padding: 5px;
  padding-bottom: 3px;
  border-radius: 8px;
  margin: 10px;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.formUk .bigArea {
  height: 6rem;
  border: solid 1px #ccc;
  padding: 5px;
  padding-bottom: 3px;
  border-radius: 8px;
  margin: 10px;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.formUk .smallArea:focus {
  outline: 0;
  border: solid 1px #ccc;
}

.formUk .bigArea:focus {
  outline: 0;
  border: solid 1px #ccc;
}

.grid {
  display: grid;
  column-gap: 35px;
  grid-template-columns: repeat(2, 45%);
}

.col {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.label {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #8f8f8f;
  font-family: "Regular";
  margin-top: 16px;
  margin-bottom: 8px;
}

.area {
  padding: 12px;
  border-color: #dcdcdc;
  border-radius: 8px;
  background-color: transparent;
  width: 400px;
}

.arabicArea {
  padding: 12px;
  border-color: #dcdcdc;
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
  text-align: right;
}

.cardInput {
  background: #ffffff;
  border: 1px solid #fbfbfb;
  box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
  border-radius: 8px;
  padding-inline: 12px;
  padding-bottom: 90px;
  height: 35vh;
}

.form::-webkit-scrollbar {
  width: 0;
}
.cardInput::-webkit-scrollbar {
  width: 0;
}

.forms textarea:focus {
  outline: 0;
}

.selectCategory {
  display: block;
  margin: 15px 0;
  padding: 12px;
  width: 400px;
  color: #8f8f8f;
  border: solid 1px #ccc;
  border-radius: 8px;
}

.selectCategory:focus,
.selectCategory:active {
  border: solid 1px #ccc;
  outline: 0;
}

@media (max-width: 991px) {
  .formUk {
    width: 100%;
    max-width: 80%;
    align-self: center;
  }
}
